import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

//$(document).foundation();

// IMPORT SLICK
// it's not modular, but we can require it in :)
require('slick-carousel/slick/slick.js');

// init screenshot slider
$('.screenshot-slider').slick({
  dots: true,
  arrows: true,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 4500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false
      }
    }
  ]
});

// video popup
// ---------------------------------------------
//open
$('.hero-game-trailer-link').click(function(e) {
  e.preventDefault();
  $('body').addClass("video-popup-open");
  // stop the video
  $('#popup-youtube-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*'); 
});

// close
$('.close-btn , .video-popup').click(function(e) {
  e.preventDefault();
  // stop the video
  $('#popup-youtube-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*'); 
  $('body').removeClass("video-popup-open");
});

// game desciption
// ---------------------------------------------
// expand more info
$('.section-description .trailing-link').click(function(e){
  e.preventDefault();
  $('.app-description-more').slideToggle();
  $('.section-description .trailing-link').toggle();
});

